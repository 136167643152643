<template>
  <!-- BEGIN: Content-->
  <div class="app-content content">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                {{ title }}
              </h2>
            </div>
          </div>
        </div>
        <div
          class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none"
        >
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-primary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
               
              @click="$router.push({name: 'researcher.postdoctoral-positions.create'})"
            ><i data-feather="plus" /></a>
            <!-- <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Professional background settings"
            ><i data-feather="settings" /></a> -->
          </div>
        </div>
      </div>

      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <CompleteTable
              :sorted-field="sortedField"
              :data="positions"
              :sort-mapping="sortMapping"
              :title="'postdoctoral & fellowships'"
              :total="positionsTotal"
              :fetch-path="'positions/fetch'"
              :filter-path="'positions/filter'"
            >
              <template v-slot="{field, showInfoSheet}">
                <td
                  class="text-end"
                >
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                  >
                    <template #button-content>
                      <i data-feather="more-vertical" />
                    </template>
                    <div
                      class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                    >
                      <b-dropdown-item @click="$router.push({name: 'researcher.dashboard', params: { id: field.user.id }})">
                        <i
                          class="me-50"
                          data-feather="user"
                        /> View researcher profile
                      </b-dropdown-item>
                      <div class="dropdown-divider" />
                      <b-dropdown-item @click="showInfoSheet(field)">
                        <i
                          class="me-50"
                          data-feather="eye"
                        /> View postdoctoral position
                      </b-dropdown-item>
                      <b-dropdown-item @click="$router.push({name: 'researcher.postdoctoral-positions.edit', params: { id: field.id }})">
                        <i
                          class="me-50"
                          data-feather="edit-3"
                        /> Edit
                      </b-dropdown-item>
                      <b-dropdown-item @click="showDeleteModal(field.id)">
                        <i
                          class="me-50"
                          data-feather="trash-2"
                        /> Delete <span class="float-end"><i data-feather="lock" /></span>
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                  <a
                    href=""
                    class="btn btn-icon btn-light-secondary d-block d-sm-none"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasMenu"
                    aria-controls="offcanvasBottom"
                  ><i
                    data-feather="more-vertical"
                  /></a>
                </td>
              </template>
              <template #customfilters>
                <div
                  v-if="loggedUser.roles.includes('super-admin')"
                  class="mb-1"
                >
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Researcher</label>
                  <v-select
                    label="name"
                    :options="users"
                    :get-option-key="option => option.id"
                    @search="onSearch({ name: $event }, 'users/fetchFakeUsers')"
                    @input="saveFilter($event, 'researcher')"
                  />
                  <hr>
                </div>
                <div class="mb-1">
                  <label
                    for="areaseniorcall"
                    class="form-label"
                  >Position</label>
                  <v-select
                    v-if="positionsList.length !== undefined"
                    label="name"
                    :options="positionsList"
                    :get-option-key="option => option.id"
                    @input="saveFilter($event, 'position')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="areaseniorcall"
                    class="form-label"
                  >Institution</label>
                  <v-select
                    v-if="institutions.length !== undefined"
                    label="name"
                    :options="institutions"
                    :get-option-key="option => option.id"
                    @input="validateInstitution($event, 'institution')"
                    @search="onSearch({ name: $event }, 'institutions/filter')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Department</label>
                  <v-select
                    v-model="department_value"
                    label="name"
                    :options="departments"
                    :get-option-key="option => option.id"
                    :disabled="departments.length === 0"
                    @input="saveFilter($event, 'department')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Country</label>
                  <input
                    type="text"
                    class="form-control"
                    name="nameseniorcall"
                    @change="saveFilter($event.target.value, 'country')"
                  >
                </div>
                <div class="mb-1">
                  <label
                    for="areaseniorcall"
                    class="form-label"
                  >Publish in web</label>
                  <select
                    class="form-select"
                    @input="saveFilter($event.target.value, 'published_web')"
                  >
                    <option
                      value=""
                      selected
                    >
                      All
                    </option>
                    <option
                      value="Publish in web"
                    >
                      Yes
                    </option>
                    <option
                      value="Don't publish in web"
                    >
                      No
                    </option>
                  </select>
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >From year</label>
                  <date-picker
                    v-model="filters['begin_date']"
                    format="Y"
                    type="year"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter($event, 'begin_year')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >To year</label>
                  <date-picker
                    v-model="filters['end_year']"
                    format="Y"
                    type="year"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter($event, 'end_year')"
                  />
                </div>
              </template>
              <template #info-sheet-item="{item}">
                <div class="offcanvas-body offcanvas-body--view">
                  <div class="mb-1 offcanvas-body--view--title">
                    <p>Researcher<br>
                      <strong>{{ item.user.name }}</strong></p>
                  </div>
                  <hr>
                  <div class="mb-1 offcanvas-body--view--title">
                    <p>Position<br>
                      <strong>{{ item.position.name }}</strong></p>
                  </div>
                  <hr>
                  <div class="mb-1">
                    <p>Institution<br>
                      <strong>{{ item.institution.name }}</strong></p>
                  </div>
                  <div class="mb-1">
                    <p>Department<br>
                      <strong>{{ item.department }}</strong></p>
                  </div>
                  <div class="row">
                    <div class="col">
                      <p>Begin year<br>
                        <strong>{{ item.begin_year }}</strong></p>
                    </div>
                    <div class="col">
                      <p>End year<br>
                        <strong>{{ item.end_year }}</strong></p>
                    </div>
                  </div>
                  <hr>
                  <h4>Certificate</h4>
                  <div class="mb-1">
                    <p>Files</p>
                    <table class="table">
                      <thead>
                        <tr>
                          <th>File name</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="file in item.certificates"
                          :key="file.id"
                        >
                          <td>{{ file.name }}</td>
                          <td class="text-end">
                            <a
                              :href="file.url"
                              target="_blank"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="download file"
                            ><i data-feather="download" /></a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <hr>
                  <div class="mb-1">
                    <p>Publish in web<br>
                      <strong
                        class="badge"
                        :class="item.published_web ? 'badge-light-success' : 'badge-light-danger'"
                      >{{ item.published_web ? 'Yes' : 'No' }}</strong></p>
                  </div>

                </div>
                <div class="offcanvas-footer mt-auto">
                  <a
                    title="edit this postdoctoral & fellowship"
                    class="btn btn-primary mb-1 d-grid w-100"
                    @click="$router.push({name: 'researcher.postdoctoral-positions.edit', params: { id: item.id }})"
                  >Edit</a>
                </div>
              </template>
            </CompleteTable>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import CompleteTable from '../../partials/components/CompleteTable.vue'

export default {
  components: {
    CompleteTable,
    BDropdown,
    BDropdownItem,
    vSelect,
    DatePicker,
  },
  data() {
    return {
      filters: [],
      departments: [],
      department_value: null,
      sortedField: 'Position',
      defaultFields: [
        {
          name: 'Researcher',
          checked: true,
          order: 1,
        },
        {
          name: 'Position',
          checked: true,
          order: 2,
        },
        {
          name: 'Institution',
          checked: true,
          order: 3,
        },
        {
          name: 'Department',
          checked: true,
          order: 4,
        },
        {
          name: 'Begin year',
          checked: true,
          order: 5,
        },
        {
          name: 'End year',
          checked: true,
          order: 6,
        },
        {
          name: 'Country',
          checked: true,
          order: 7,
        },
        {
          name: 'Publish in web',
          checked: true,
          order: 8,
        },
      ],
      sortMapping: {
        Researcher: 'user.name',
        Position: 'position_table',
        Institution: 'institution_table',
        Department: 'department_table',
        'Begin year': 'begin_year',
        'End year': 'end_year_table',
        Country: 'country_table',
        'Publish in web': 'published_web',
      },
      title: 'Postdoctoral positions & fellowships',
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      positions: 'positions/positions',
      positionsTotal: 'positions/positionsTotal',
      positionsList: 'positions/positionsList',
      institutions: 'institutions/institutions',
      users: 'users/fakeUsers',
      loggedUser: 'auth/admin',
      filtersAux: 'filters/filters',
    }),
  },
  async mounted() {
    await this.$store.dispatch('modals/fetchUserFields', 'postdoctoral-positions')
    await this.$store.dispatch('positions/filter', this.filtersAux)
    await this.$store.dispatch('positions/fetchPositionsList')
    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'postdoctoral-positions',
      })
    }
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    saveFilter(value, field) {
      this.$store.dispatch('filters/saveFilters', {
        value,
        field,
      })
    },
    async onSearch(search, store) {
      search.active = true
      if (search.name !== '') {
        await this.$store.dispatch(store, search)
      }
    },
    async showDeleteModal(id) {
      await this.$store.dispatch('modals/saveDeleteData', { name: ['positions/fetch', 'positions/fetchPositionsList'], url: `${Vue.prototype.$groupUrl}/positions/${id}` })
      this.$store.dispatch('modals/showDeleteModal', true)
    },
    validateInstitution(value, field) {
      this.saveFilter(value, field)
      this.department_value = null
      this.departments = value.departments
    },
  },
}
</script>
